<template>
    <div id="pageTable">      
        <v-container grid-list-xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Capacitaciones'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        @loadModalData="loadModalData"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas de ingreso</span>
                                </div>
                            </v-col>

                            <v-col xs="12" sm="6" md="6" class="py-0">
                                 <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicial"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>

                            <v-col xs="12" sm="6" md="6" class="py-0">
                                 <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                            <v-col xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div @click="detalleCapacitacion(item)" class="cursor-pointer">{{item.nombre}}</div></td>
                                <td><div>{{dateFormat(item.fecha_inicio)}}</div></td>
                                <td><div>{{dateFormat(item.fecha_fin)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModalEmpleados(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">person_add</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Empleados del curso</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row> 
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre" 
                                                        v-model="cursoCapacitacion.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Total de horas" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Total de horas" 
                                                        
                                                        v-model="cursoCapacitacion.total_horas" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                 <VuetifyDatePicker
                                                    outlined
                                                    v-model="cursoCapacitacion.fecha_inicio"
                                                    label="Fecha inicial"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaInicioCapacitacion"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    v-model="cursoCapacitacion.fecha_fin"
                                                    label="Fecha fin"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinalCapacitacion"
                                                />
                                            </v-col>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                        
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogEmpleados" persistent max-width="800px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalEmpleados"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <div id="loadingModal" v-show="isLoadingModalEmpleados">
                                        <div id="loading">
                                            <v-progress-circular
                                                indeterminate
                                                :size="100"
                                                :width="7"
                                                color="blue"
                                            ></v-progress-circular>
                                        </div>
                                    </div>
                                    <v-container v-show="!isLoadingModalEmpleados" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row> 
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <p class="titleModal_600_15">Información general</p>
                                            </v-col>
                                            <p class="col-4 py-0 subtitle">Nombre del curso:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.nombre != null ? '':'text-mute'">{{cursoCapacitacion.nombre!= null ? cursoCapacitacion.nombre:"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Total de horas:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.total_horas != null ? '':'text-mute'">{{cursoCapacitacion.total_horas!= null ? cursoCapacitacion.total_horas:"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Fecha inicial:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.fecha_inicio != null ? '':'text-mute'">{{cursoCapacitacion.fecha_inicio!= null ? formatoFecha(cursoCapacitacion.fecha_inicio):"Información faltante*"}}</span></p>
                                            <p class="col-4 py-0 subtitle">Fecha final:</p>
                                            <p class="col-8 py-0 px-0 text"><span :class="cursoCapacitacion.fecha_fin != null ? '':'text-mute'">{{cursoCapacitacion.fecha_fin!= null ? formatoFecha(cursoCapacitacion.fecha_fin):"Información faltante*"}}</span></p>
                                   

                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    readonly
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    readonly
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="11" sm="11" md="11" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empleados"
                                                    :items="empleados"
                                                    item-text="nombre_completo"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empleadosModel"
                                                    multiple
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item
                                                            ripple
                                                            class="py-0 my-0"
                                                            @click="toggleAllEmpledos"
                                                        >
                                                            <v-list-item-action>
                                                                <v-icon :color="empleadosModel.length > 0 ? 'indigo darken-4' : ''">
                                                                    {{ icon }}
                                                                </v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    Seleccionar todos
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                    <template v-slot:selection="{ item, index }" >
                                                        <v-chip v-if="index === 0">
                                                            <span>{{ item.nombre_completo }}</span>
                                                        </v-chip>
                                                        <v-chip v-if="index === 1">
                                                            <span>{{ item.nombre_completo }}</span>
                                                        </v-chip>
                                                        <span
                                                            v-if="index === 2"
                                                            class="grey--text caption ml-1"
                                                        >
                                                            (+{{ empleadosModel.length - 2 }} otros)
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="1" sm="1" md="1" class="py-0">
                                                <v-btn color="#1E2245" class="mt-2" elevation="0" dark fab  @click="agregarEmpleados()" small>
                                                    <v-icon dark>add</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="empleadosLista.length > 0">
                                            <v-col cols="12" xs="12" sm="12" md="12">
                                                <h2 class="subtituloEmpleado mt-7 ml-2">Empleados</h2>
                                            </v-col>
                                            <template v-for="(i,index) in empleadosLista">
                                                <v-col cols="12" xs="12" sm="12" md="3" :key="i.id" class="pt-0 pb-0 ma-0">
                                                    <v-text-field label="Empleado" outlined readonly type="text" :value="i.nombre_completo"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="5" md="2" :key="'H'+i.id" class="pt-0 pb-0 ma-0">
                                                    <v-text-field 
                                                        label="Evaluación" 
                                                        outlined 
                                                        v-model="i.evaluacion"  
                                                        oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        v-on:keypress="presicionNumero($event,i.evaluacion)"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="5" md="6" :key="'I'+i.id" class="pt-0 pb-0 ma-0">
                                                    <v-text-field type="text" label="Observaciones" outlined v-model="i.observaciones"  ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" md="1" :key="'K'+i.id" class="py-2">
                                                    <v-btn class="botonHover" icon fab dark small @click="eliminarEmpleado(index, i.id)" >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                    <v-btn class="btnGuardar" @click="guardarEmpleados()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/CursosCapacitaciones';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import sucursalApi from '@/api/sucursales';
import departamentoApi from '@/api/departamentos';
import Notify from '@/plugins/notify';
import apiCursoCapacitacion from '@/api/cursoCapacitacion';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Fecha Inicio',
                    name: 'fecha_inicio',
                    filterable: true
                },
                {
                    label: 'Fecha Fin',
                    name: 'fecha_fin',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            cursoCapacitacion           : {
                id                      : null,
                cliente_id              : null,
                empresa_id              : null,
                nombre                  : null,
                fecha_inicio            : null,
                fecha_fin               : null,
                total_horas             : null
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            imagen          : "",
            dialog          : false,
            isSaving        : false,
            menu_inicio     : null,
            menu_final      : null,
            rol             : null,
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            fecha_inicio    : null,
            fecha_final     : null,
            nombre :null,
            calendar_inicio : false,
            calendar_fin    : false,
            isLoadingModal  : false,
            dialogEmpleados : false,
            isLoadingModalEmpleados : false,
            modalAgregarEmpleados   : false,
            empleados       : [],
            empleadosModel  : [],
            empleadosLista  : [],
            empleadosOriginal: [],
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
        fecha_inicio: function(){
            if(this.fecha_final == "" || this.fecha_final == null){
                this.$refs.FechaFinal.focus();
            }
        },
        fecha_final: function(){
            if(this.fecha_inicio == "" || this.fecha_inicio == null){
                this.$refs.FechaInicio.focus();
            } 
        },
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        empresa_value: function(val, oldVal){
            if(val != null && this.modalAgregarEmpleados){
                this.cambiaEmpresa(val);
                this.getEmpleados();
            }
        },
    },
    computed: {
        likesAllEmpleados () {
            return this.empleadosModel.length === this.empleados.length
        },
        likesSomeEmpleados () {
            return this.empleadosModel.length > 0 && !this.likesAllEmpleados
        },
        icon () {
            if (this.likesAllEmpleados) return 'mdi-close-box'
            if (this.likesSomeEmpleados) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
         dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        /**
         * @method formatoFecha Esta método parsea una fecha
         * @description Convierte un fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se utiliza en el formateo de fecha del modal para agregar empleados.
         * @param {string} fecha formato AAAA-MM-DD
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        formatoFecha(fecha){
            let fecha2 = fecha.split("-");
            return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
        },
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.filters = { AND:[]};
            if (this.rol == "root") {
                this.getClientes();
            }

            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal =  true;

            this.getClientes();
            if(accion == "add"){
                this.tituloModal    = "Registrar Curso-Capacitación";
                this.imagen         = "/static/modal/cursoCapacitacionCreate.svg";
                
            } else {
                this.tituloModal                    = "Actualizar Curso-Capacitación";
                this.imagen                         = "/static/modal/cursoCapacitacionUpdate.svg";
                this.cursoCapacitacion.id           = data.id;
                this.cursoCapacitacion.cliente_id   = data.cliente_id;
                this.cursoCapacitacion.empresa_id   = data.empresa_id;
                this.cliente_value                  = data.cliente_id;
                this.empresa_value                  = data.empresa_id;
                this.cursoCapacitacion.nombre       = data.nombre;
                this.cursoCapacitacion.fecha_inicio = data.fecha_inicio;
                this.cursoCapacitacion.fecha_fin    = data.fecha_fin;
                this.cursoCapacitacion.total_horas  = data.total_horas;

                setTimeout(()=>this.$refs.FechaInicioCapacitacion.setDate(data.fecha_inicio),200);
                setTimeout(()=>this.$refs.FechaFinalCapacitacion.setDate(data.fecha_fin),200);

            }

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id, this.empresa_id);
            } else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            this.isLoadingModal = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
            
        },
        cerrarModal() {
            this.dialog     = false;
            this.dialogEmpleados = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            if(this.$refs.FechaInicioCapacitacion != undefined || this.$refs.FechaInicioCapacitacion != null){
                this.$refs.FechaInicioCapacitacion.resetDate();
            }
            if(this.$refs.FechaFinalCapacitacion != undefined || this.$refs.FechaFinalCapacitacion != null){
                this.$refs.FechaFinalCapacitacion.resetDate();
            }

            this.modalAgregarEmpleados  = false;
            this.empleadosModel         = [];
            this.empleadosLista         = [];
            this.empleadosOriginal      = [];
            this.empleados              = [];

            this.$nextTick(() => {
                this.cursoCapacitacion      = {
                    id                      : null,
                    cliente_id              : null,
                    empresa_id              : null,
                    nombre                  : null,
                    fecha_inicio            : null,
                    fecha_fin               : null,
                    total_horas             : null
                }

                if(this.rol == 'root'){
                    this.cliente_value      = null;
                    this.empresa_value      = null;
                    this.empresas           = [];
                }

                if(this.rol == 'admin'){
                    this.empresa_value      = null;
                } 

                this.nombre = null;
                this.fecha_inicio = null;
                this.fecha_final = null;

                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },
        loadModalData() {
            this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            }
            else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

        },
        setFilters() {
            this.filters = {AND:[]};
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let dates = 0;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 

            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                this.filters.AND.push({column:'FECHA_INICIO', operator:'GTE', value:fecha_inicio});
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                this.filters.AND.push({column:'FECHA_FIN', operator:'LTE', value:fecha_final});
            }

            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){3
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }

            this.paginationData.numberPage = 1;
            this.$apollo.queries.cursos_capacitaciones.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el curso-capacitación " + data.nombre + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiCursoCapacitacion.delete(data.id).then(response => {
                        Notify.Success("Curso-Capacitación eliminado", "El curso-capacitación ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.cursos_capacitaciones.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false }
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        cambiaCliente(id, cliente = null) {
            if(this.cursoCapacitacion.cliente_id != id){
                this.empresa_value = null;
                this.cursoCapacitacion.empresa_id = null;
            }
            
            if(id == null){
                return;
            }
            
            let parametros = { activo: true, paginate: false, cliente_id: id };

            empresaApi.getEmpresas(parametros).then((response)=>{
                this.empresas = response.data;
                if(this.cursoCapacitacion.empresa_id != null){
                    
                }
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        /**
         * @method cambiaEmpresa Este método realiza petición a la API de sucursales.
         * @description Realiza petición a la API de sucursales cuando la empresa es seleccionada y el resultado lo almacena en el array sucursales[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
         * @param {int} empresa_id ID de la empresa seleccionada. 
        */
        cambiaEmpresa(empresa_id) {
            let parametros = {activo: true, paginate: false, cliente_id: this.cliente_value, empresa_id : empresa_id};

            sucursalApi.getSucursales(parametros).then((response)=>{
                this.sucursales = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast("No se pudo realizar la petición de sucursales.");
            });
        },

        /**
         * @method cambiaSucursal Este método realiza petición a la API de departamentos.
         * @description Realiza petición a la API de departamentos cuando la sucursal es seleccionada y el resultado lo almacena en el array departamentos[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
         * @param {int} sucursal_id ID de la sucursal seleccionada. 
        */
        cambiaSucursal(sucursal_id) {
            let parametros = { activo: true, paginate: false, cliente_id: this.cliente_value, empresa_id : this.empresa_value, sucursal_id : sucursal_id };

            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
            })
            .catch(error => {
                this.resetValues();
                 Notify.ErrorToast("No se pudo realizar la petición de departamentos.");
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let cliente = this.cliente_value;
                let empresa = this.empresa_value;

                if (this.rol == "admin") {
                    cliente = this.datosLogin.cliente_id;
                } else if (this.rol == "admin-empresa") {
                    cliente = this.datosLogin.cliente_id;
                    empresa = this.datosLogin.empresa_id;
                } 

                this.cursoCapacitacion.cliente_id      = cliente;
                this.cursoCapacitacion.empresa_id      = empresa;

                this.isLoading = true;
                if(this.accion === "add") {
                    apiCursoCapacitacion.store(this.cursoCapacitacion)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El curso-capacitación se guardó satisfactoriamente.");
                        this.$apollo.queries.cursos_capacitaciones.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiCursoCapacitacion.update(this.cursoCapacitacion)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El curso-capacitación se actualizó satisfactoriamente.");
                        this.$apollo.queries.cursos_capacitaciones.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        abrirModalEmpleados(data = null){
            this.tituloModal                    = "Empleados del curso";
            this.imagen                         = "/static/modal/cursoCapacitacionUpdate.svg";
            this.modalAgregarEmpleados          =  true;
            this.cursoCapacitacion.id           = data.id;
            this.cursoCapacitacion.cliente_id   = data.cliente_id;
            this.cursoCapacitacion.empresa_id   = data.empresa_id;
            this.cliente_value                  = data.cliente_id;
            this.empresa_value                  = data.empresa_id;
            this.cursoCapacitacion.nombre       = data.nombre;
            this.cursoCapacitacion.fecha_inicio = data.fecha_inicio;
            this.cursoCapacitacion.fecha_fin    = data.fecha_fin;
            this.cursoCapacitacion.total_horas  = parseFloat(data.total_horas);

            this.isLoadingModalEmpleados = false;
            setTimeout(function(){ document.getElementById("btnModalEmpleados").click(); }, 100);
        },
        /**
         * @method getEmpleados Este método realiza petición a la API de empleados relacionados con la capacitación.
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(){
            let parametros = { 
                activo: true, 
                paginate: false,
                usuario_id : this.datosLogin.id,
                curso_id : this.cursoCapacitacion.id,
                cliente_id: this.cliente_value, 
                empresa_id : this.empresa_value, 
                sucursal_id : this.sucursal_value, 
                departamento_id : this.departamento_value,
            };

            apiCursoCapacitacion.getEmpleados(parametros).then((response)=>{
                let empleadosResponse = response.data;
                this.empleados = response.data;
                /* this.empleadosOriginal = empleadosResponse; */
            })
            .catch(error => {
                this.resetValues();
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        /**
         * @method toggleAllEmpledos Este método selecciona todos los empleados o los quita.
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        toggleAllEmpledos () {
            this.$nextTick(() => {
                if (this.likesAllEmpleados) {
                    this.empleadosModel = [];
                } else {
                    this.empleadosModel = [];
                    if(this.empleados.length > 0 ){
                        for (let empleado = 0; empleado < this.empleados.length; empleado++) {
                            this.empleadosModel.push(this.empleados[empleado].id);
                        }
                    }
                }
            })
        },

        /**
         * @method agregarEmpleados Este método para seleccionar todos los empleados.
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        agregarEmpleados(){
            if(this.empleadosModel.length > 0){
                if(this.empleadosLista.length > 0){
                    for(let empleado = 0; empleado < this.empleadosModel.length; empleado++){
                        let evaluarEmpleado = this.empleadosLista.find(element => element.id == this.empleadosModel[empleado]);
                        if(evaluarEmpleado == undefined){
                            let emp = this.empleados.find(element => element.id == this.empleadosModel[empleado]);
                            let empleadoRegistro = { 
                                id              : this.empleadosModel[empleado], 
                                evaluacion      : null, 
                                observaciones   : null,
                                nombre_completo : emp.nombre_completo, 
                            }
                            this.empleadosLista.push(empleadoRegistro);
                        }
                    }
                }else{
                    for(let empleado = 0; empleado < this.empleadosModel.length; empleado++){
                        let emp = this.empleados.find(element => element.id == this.empleadosModel[empleado]);
                        let empleadoRegistro = { 
                            id              : this.empleadosModel[empleado], 
                            evaluacion      : null, 
                            observaciones   : null,
                            nombre_completo : emp.nombre_completo, 
                        }
                        this.empleadosLista.push(empleadoRegistro);
                    }
                }                
            }
            else{
                Notify.ErrorToast("Seleccione al menos un empleado.");
            }
        },

        /**
         * @method guardarEmpleados Este método sirve para guardar la lista de guardar empleados, 
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        guardarEmpleados(){
            let self = this;
            this.isSaving = true;
            let parametros = {curso_id: this.cursoCapacitacion.id, empleados: this.empleadosLista};
            apiCursoCapacitacion.storeEmpleados(parametros).then((response) => {
                Notify.Success("Operación exitosa", "La capacitación se guardó satisfactoriamente.");
                this.cerrarModal();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            })
            .catch(err => {
                this.isSaving = false;
                this.isLoading = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },

        /**
         * @method eliminarEmpleado Este método sirve para guardar la lista de guardar empleados, 
         * @description Método que realiza el almacenamiento de todos los id de los empleados listados en el array empleadosModel[] o quitar todos los id de los empleados.
        */
        eliminarEmpleado(index, idEmpleado){
            this.empleadosLista.splice(index,1);
        },

        /**
         * @method detalleCapacitacion Este método valida redirecciona a la vista del detalle de la capacitación
         * @description Este método valida redirecciona a la vista del detalle de la capacitación
         * @param {Object} data datos de la capacitación.
        */
        detalleCapacitacion(data) {
            this.$session.remove("detalleCapacitacion");
            this.$session.set("detalleCapacitacion", data);
            this.$router.push("/detalleCapacitacion");
        },

        /**
         * @method presicionNumero Este método valida que la calificación de un empleado no tenga mas de 3 decimales
         * @description Valida que calificación que se pasa no tenga mas de tres decimales.
         * @param {event} event evento de tecla.
         * @param {int} valor número
         * @return preventDefault
        */
        presicionNumero(event, valor){
            if (/\.\d{3}/.test(valor)) 
                return event.preventDefault();
        },

        /**
         * @method validarFecha Este método valida una fecha
         * @description Valida la fecha que se pase y devuelve true en caso de que la fecha sea verdadero y falso en caso de que la fecha sea incorrecta
         * @param {string} valorFecha formato AAAA-MM-DD
         * @return {boolean} devuelve un true o false.
        */
        validarFecha(valorFecha) {
            try{
                let fecha = valorFecha.split("-");        
                let dia = fecha[2];        
                let mes = fecha[1];        
                let ano = fecha[0];        
                let estado = true;  
                let dmax = 0;
                let diaC = null;
                let mesC =  null;
                let anoC = null;
                
                if ((dia.length == 2) && (mes.length == 2) && (ano.length == 4)) {        
                    switch (parseInt(mes)) {        
                        case 1:dmax = 31;break;        
                        case 2: 
                            if (((ano % 4 == 0) && (ano % 100 != 0 )) || (ano % 400 == 0))
                                dmax = 29; 
                            else 
                                dmax = 28;        
                            break;        
                        case 3:dmax = 31;break;        
                        case 4:dmax = 30;break;        
                        case 5:dmax = 31;break;        
                        case 6:dmax = 30;break;        
                        case 7:dmax = 31;break;        
                        case 8:dmax = 31;break;        
                        case 9:dmax = 30;break;        
                        case 10:dmax = 31;break;       
                        case 11:dmax = 30;break;      
                        case 12:dmax = 31;break;       
                    }  
                    
                    dmax!=""?dmax:dmax=-1;
                    
                    if ((dia >= 1) && (dia <= dmax) && (mes >= 1) && (mes <= 12)) {
                        let i=0;
                        for (i=0 ; i<fecha[2].length; i++) {      
                            diaC = fecha[2].charAt(i).charCodeAt(0);        
                            (!((diaC > 47) && (diaC < 58)))?estado = false:'';       
                            mesC = fecha[1].charAt(i).charCodeAt(0);        
                            (!((mesC > 47) && (mesC < 58)))?estado = false:'';       
                        }  
                    } 
                    else{
                        estado = false;
                    }
                    
                    let j = 0;
                    for(j=0; j < fecha[0].length; j++) {
                        anoC = fecha[0].charAt(j).charCodeAt(0);  
                        (!((anoC > 47) && (anoC < 58)))?estado = false:'';        
                    }
                } 
                else {
                    estado = false;
                }        
                return estado; 
            }catch(err){
                return false;
            }  
        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("catalogo.cursos-capacitaciones.store")
            this.permisos.update = permisosStorage.includes("catalogo.cursos-capacitaciones.update")
            this.permisos.delete = permisosStorage.includes("catalogo.cursos-capacitaciones.delete")
        },
    },
    mounted(){
        this.$apollo.queries.cursos_capacitaciones.skip = false;
    },
    created () {
        this.listar();
        this.permisosVista();
    },
    apollo: {
        cursos_capacitaciones: {
            query       : queries.cursosCapacitacionesTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.cursos_capacitaciones;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .text-mute{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .subtituloEmpleado{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .cursor-pointer{
        cursor:pointer;
    }
</style>
